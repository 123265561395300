import { find, filter, groupBy, fromPairs, toPairs, sortBy } from 'lodash-es'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_PUZZLE
} from '~/store/program/state.js'

const programKindOrder = {
  [PROGRAM_KIND_POINTS_VIA_PRICE]: 1, // points first
  [PROGRAM_KIND_POINTS_BY_PURCHASE]: 2,
  [PROGRAM_KIND_STAMPS]: 3,
  [PROGRAM_KIND_PUZZLE]: 4
}

export const programs = (state, _, rootState) => {
  if (!state.declaration) return []
  if (!rootState.user.loyaltyPrograms) return []

  const unauthorizedProgramIdentifiers =
    state.declaration.details.on_dupe_program_identifiers

  if (!unauthorizedProgramIdentifiers) {
    return rootState.user.loyaltyPrograms
  }

  return rootState.user.loyaltyPrograms.filter(
    (program) => !unauthorizedProgramIdentifiers.includes(program.identifier)
  )
}

export const availablePrograms = (_, getters) =>
  filter(getters.programs, (program) => program.is_active)

export const availableProgramIdentifiers = (_, getters) =>
  getters.availablePrograms.map((p) => p.identifier)

export const availableProgramsByKind = (_, getters) =>
  groupBy(getters.availablePrograms, 'kind')

export const availableGenericProgramsByKind = (_, getters) =>
  fromPairs(
    sortBy(
      filter(
        toPairs(getters.availableProgramsByKind),
        ([kind, _]) => kind in programKindOrder
      ),
      ([kind, _]) => programKindOrder[kind]
    )
  )

export const isMultipleProgramTypesAvailable = (_, getters) =>
  Object.keys(getters.availableProgramsByKind).length > 1

export const availableProgramsForPoints = (_, getters) =>
  (getters.availableProgramsByKind[PROGRAM_KIND_POINTS_VIA_PRICE] || []).concat(
    getters.availableProgramsByKind[PROGRAM_KIND_POINTS_BY_PURCHASE] || []
  )

export const availableProgramsForStamps = (_, getters) =>
  getters.availableProgramsByKind[PROGRAM_KIND_STAMPS]
    ? getters.availableProgramsByKind[PROGRAM_KIND_STAMPS]
    : []

export const availableProgramsForPuzzlePieces = (_, getters) =>
  getters.availableProgramsByKind[PROGRAM_KIND_PUZZLE]
    ? getters.availableProgramsByKind[PROGRAM_KIND_PUZZLE]
    : []

export const availableProgramsForCollectors = (_, getters) =>
  getters.availableProgramsByKind[PROGRAM_KIND_COLLECTOR]
    ? getters.availableProgramsByKind[PROGRAM_KIND_COLLECTOR]
    : []

export const anyAvailableProgramForPoints = (_, getters) =>
  getters.availableProgramsForPoints.length > 0

export const anyAvailableProgramForCollectors = (_, getters) =>
  getters.availableProgramsForCollectors.length > 0

export const noAvailablePrograms = (_, getters) =>
  getters.availablePrograms === 0

export const anyAvailablePrograms = (_, getters) =>
  getters.availablePrograms.length > 0

export const programsExpiredForPoints = (_, getters) =>
  filter(
    getters.programs,
    (program) =>
      program.kind === PROGRAM_KIND_POINTS_VIA_PRICE && !program.is_active
  )

export const programsExpiredForPointsWithEarnedCoupons = (_, getters) =>
  filter(
    getters.programsExpiredForPoints,
    (program) => program.data.earned_coupons.length > 0
  )

export const anyProgramsExpiredForPointsWithEarnedCoupons = (_, getters) =>
  getters.programsExpiredForPointsWithEarnedCoupons.length > 0

export const availablePuzzleMissionsToAddOnDeclaration = (_, getters) => (
  programIdentifier
) => {
  if (getters.availableProgramsForPuzzlePieces.length === 0) return []

  const program = find(
    getters.availableProgramsForPuzzlePieces,
    (p) => p.identifier === programIdentifier
  )
  if (!program) return []

  return filter(
    program.kind_data.puzzle_missions,
    (pm) =>
      pm.can_be_added_on_declaration &&
      (pm.remaining_times === null || pm.remaining_times > 0)
  )
}

export const declarationIsRejected = (state) =>
  state.declaration.is_eligible === false

export const declarationIsNotRejected = (_state, getters) =>
  !getters.declarationIsRejected
