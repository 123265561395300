import CANCEL_TOKEN_ERROR_ID from '@/utils/constants/cancel-token-error-id'
import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

export default async ({ ctx, _store, _tab, payload }) => {
  const { state, rootState, commit } = ctx

  // not sure if relevant
  // could be left out to be more general
  if (!rootState.metrics.program.selectedPeriod) return

  commit('reset')
  commit('setLoadingState', true)

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })

  const delayinseconds = 1 + Math.floor(Math.random() * 5)
  const promiseDelay = new Promise((resolve) =>
    setTimeout(resolve, delayinseconds * 1000)
  )

  await Promise.race([promiseDelay, cancelToken.promise])

  try {
    cancelToken.throwIfRequested()
  } catch (error) {
    if (error.message === CANCEL_TOKEN_ERROR_ID) return
  }

  // if (response.data.result.errors) {
  //   commit(
  //     'metrics/program/setTabErrors',
  //     { tab, errors: response.data.result.errors },
  //     { root: true }
  //   )
  //   commit('setLoadingState', false)
  //   return
  // }

  commit('handleResult', payload)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
