<template>
  <div class="benefits">
    <v-data-table
      class="benefits__table"
      :headers="BENEFITS_HEADERS"
      :items="items"
      item-class="rowClass"
      disable-pagination
      hide-default-footer
      :locale="$i18n.locale"
      @click:row="openEarnsBurnsDetails"
    >
      <template #item.program_name="{ item }">
        <v-avatar v-if="item.program_roundable_image_url" tile>
          <v-img
            :src="item.program_roundable_image_url"
            :alt="item.program_name"
          />
        </v-avatar>
        <span v-else>
          {{ item.program_name }}
        </span>
      </template>
      <template #item.total_price="{ item }">
        {{ item.total_refunded_amount | money({ suffix: ' €' }) }}
      </template>
      <template #item.total_refunded_amount="{ item }">
        {{ item.total_refunded_amount | money({ suffix: ' €' }) }}
      </template>
    </v-data-table>

    <BenefitsModal
      v-if="selectedItem"
      :program-name="selectedItem.program_name"
      :program-identifier="selectedItem.identifier"
      @close="selectedItem = null"
    />
  </div>
</template>

<script>
import BenefitsModal from './BenefitsModal'

const KEYS_WITHOUT_PROGRAM_NAME = [
  'total_quantity',
  'total_price',
  'total_points_earned',
  'total_points_burned',
  'total_refunds_count',
  'total_refunded_amount'
]
const KEYS_WITH_PROGRAM_NAME = ['program_name'].concat(
  KEYS_WITHOUT_PROGRAM_NAME
)

const KEYS_FLOATS = ['total_price', 'total_refunded_amount']
const sortFloats = (a, b) => parseFloat(a) - parseFloat(b)

export default {
  components: { BenefitsModal },
  props: {
    benefits: {
      type: Array,
      required: true
    }
  },
  data() {
    const keys =
      this.benefits.length > 1
        ? KEYS_WITH_PROGRAM_NAME
        : KEYS_WITHOUT_PROGRAM_NAME
    return {
      BENEFITS_HEADERS: keys.map((key) => ({
        text: this.$t(`pages.user.profile.table.headers.${key}`),
        value: key,
        align: key === 'program_name' ? 'center' : 'right',
        sort: KEYS_FLOATS.includes(key) ? sortFloats : undefined
      })),
      selectedItem: null
    }
  },
  computed: {
    items() {
      return this.benefits.map((benefit) => ({
        ...benefit,
        rowClass: 'clickable'
      }))
    }
  },
  methods: {
    openEarnsBurnsDetails(item, _$vuetify) {
      this.selectedItem = item
    }
  }
}
</script>
