import * as errors from '../../utils/constants/errors'

export default {
  unknown: 'Une erreur est survenue.',
  server: {
    [errors.SERVER_NOT_REACHABLE]: 'Impossible de se connecter au serveur.',
    [errors.SERVER_ERROR]: 'Une erreur est survenue sur le serveur.',
    [errors.UNKNOWN_ERROR_SERVER]: 'Une erreur inconnue est survenue.'
  },
  known: {
    [errors.SERVER_NOT_REACHABLE]: 'Impossible de se connecter au serveur.',
    [errors.SERVER_ERROR]: 'Une erreur est survenue sur le serveur.',
    [errors.UNKNOWN_ERROR_SERVER]: 'Une erreur inconnue est survenue.',

    // TODO: refactor things correctly and remove this
    [errors.UNKNOWN_ERROR_USER]: 'Un paramètre donné est incorrect.'
  },
  user: {
    // auth
    [errors.UNKNOWN_EMAIL_OR_PASSWORD]: 'Email ou mot de passe incorrect.',
    [errors.ACCESS_REVOKED]: 'Votre accès au backoffice a été révoqué.',
    [errors.UNAUTHORIZED]:
      'Vos identifiants semblent expirés, essayez de vous reconnecter.',

    // file upload
    [errors.INVALID_CONTENT_TYPE]:
      "Ce type de fichier n'est pas pris en charge.",
    [errors.MISSING_HEADER]: "Certaine(s) colonne(s) n'ont pas été trouvée(s).",

    [errors.UNKNOWN_ERROR_USER]: 'Un paramètre donné est incorrect.'
  },

  layout: {
    pageNotFound: '404 Page introuvable',
    otherError: 'Une erreur est survenue'
  },

  'do-not-worry':
    'Nous en avons été informés et nous allons régler le problème dans les plus brefs délais.',

  auth: {
    unauthorizedToViewPage: "Vous n'êtes pas autorisé à voir cette page."
  },

  program: {
    notSelected: 'Aucun programme sélectionné'
  },
  classification: {
    originGroupNotSelected: "groupe d'origine non sélectionné",
    targetGroupNotSelected: 'groupe de destination non sélectionné',
    noDenomsSelected: 'aucun libellé sélectionné'
  }
}
