<template>
  <LayoutDefault>
    <template v-if="hasNewAction && hasRight" #app-bar__right>
      <v-btn depressed :color="newButtonColor" @click="isModalNewOpen = true">
        {{ newButtonText }}
      </v-btn>
    </template>

    <template v-if="$scopedSlots['app-bar__extension']" #app-bar__extension>
      <slot name="app-bar__extension" />
    </template>

    <template #default>
      <v-container fluid>
        <slot v-if="$scopedSlots['table-prepend']" name="table-prepend" />

        <div v-if="allowNotFilteringBasedOnLoyaltyProgram && isPurcheaseAdmin">
          <v-checkbox
            v-model="doNotFilterBasedOnLoyaltyProgram"
            :label="$t('global.doNotFilterBasedOnLoyaltyProgram')"
          />
        </div>

        <div>
          <div
            v-if="errorOnFetchConfig"
            class="d-flex justify-center align-center"
          >
            {{ errorOnFetchConfig }}
            <v-btn
              x-small
              class="ml-5"
              @click="retry"
              v-text="$t('global.retry')"
            />
          </div>

          <v-skeleton-loader
            v-else-if="isConfigLoading"
            type="table-heading, table-thead, table-tbody, table-tfoot"
          />

          <div v-else-if="!isConfigLoaded" />

          <CrudIndex
            v-else
            v-model="refresh"
            :config="config.index"
            :actions="config.actions"
            :use-case="useCase"
            :do-not-filter-based-on-loyalty-program="
              doNotFilterBasedOnLoyaltyProgram
            "
            @open-show-modal="openShowModal"
            @open-edit-modal="openEditModal"
            @open-duplicate-modal="openDuplicateModal"
          >
            <template
              v-for="(index, name) in scopedSlotsForChild"
              #[name]="data"
            >
              <slot :name="name" v-bind="data" />
            </template>
          </CrudIndex>
        </div>

        <v-dialog
          v-if="hasNewAction"
          v-model="isModalNewOpen"
          v-bind="newModalProps"
        >
          <slot
            name="modal-new"
            :isOpen="isModalNewOpen"
            :config="config.new"
            :onClose="
              () => {
                isModalNewOpen = false
              }
            "
            :onRecordCreated="
              () => {
                isModalNewOpen = false
                refresh = true
              }
            "
          />
        </v-dialog>

        <v-dialog
          v-if="hasShowAction"
          v-model="isModalShowOpen"
          v-bind="showModalProps"
        >
          <slot
            name="modal-show"
            :isOpen="isModalShowOpen"
            :record="selectedRecord"
            :config="config.show"
            :onClose="
              () => {
                isModalShowOpen = false
                selectedRecord = {}
              }
            "
          />
        </v-dialog>

        <v-dialog
          v-if="hasEditAction"
          v-model="isModalEditOpen"
          v-bind="editModalProps"
        >
          <slot
            name="modal-edit"
            :isOpen="isModalEditOpen"
            :record="selectedRecord"
            :config="config.edit"
            :onClose="
              () => {
                isModalEditOpen = false
                selectedRecord = {}
              }
            "
            :onRecordCreated="
              () => {
                isModalEditOpen = false
                selectedRecord = {}
                refresh = true
              }
            "
          />
        </v-dialog>

        <v-dialog
          v-if="hasDuplicateAction"
          v-model="isModalDuplicateOpen"
          v-bind="duplicateModalProps"
        >
          <slot
            name="modal-duplicate"
            :isOpen="isModalDuplicateOpen"
            :record="selectedRecord"
            :config="config.duplicate"
            :onClose="
              () => {
                isModalDuplicateOpen = false
                selectedRecord = {}
              }
            "
            :onRecordCreated="
              () => {
                isModalDuplicateOpen = false
                selectedRecord = {}
                refresh = true
              }
            "
          />
        </v-dialog>
      </v-container>
    </template>
  </LayoutDefault>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { filter, fromPairs } from 'lodash-es'

import LayoutDefault from './default'
import CrudIndex from '@/components/features/crud/Index'
import MixinAjaxToCore from '@/mixins/ajaxToCore'

export default {
  components: {
    LayoutDefault,
    CrudIndex
  },
  mixins: [MixinAjaxToCore],
  props: {
    useCase: {
      type: String,
      required: true
    },
    newButtonText: {
      type: String,
      default: undefined
    },
    newButtonColor: {
      type: String,
      default: undefined
    },
    newModalProps: {
      type: Object,
      default: () => ({})
    },
    showModalProps: {
      type: Object,
      default: () => ({})
    },
    editModalProps: {
      type: Object,
      default: () => ({})
    },
    duplicateModalProps: {
      type: Object,
      default: () => ({})
    },
    allowNotFilteringBasedOnLoyaltyProgram: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    doNotFilterBasedOnLoyaltyProgram: false,
    isConfigLoaded: false,
    isConfigLoading: false,
    errorOnFetchConfig: null,
    config: null,
    selectedRecord: {},
    isModalNewOpen: false,
    isModalShowOpen: false,
    isModalEditOpen: false,
    isModalDuplicateOpen: false,
    refresh: false
  }),
  computed: {
    ...mapMutations('crud/messages', ['modified']),
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', ['isPurcheaseAdmin', 'permittedActions']),

    hasNewAction() {
      return this.isConfigLoaded && this.config.actions.includes('new')
    },
    hasShowAction() {
      return this.isConfigLoaded && this.config.actions.includes('show')
    },
    hasEditAction() {
      return this.isConfigLoaded && this.config.actions.includes('edit')
    },
    hasDuplicateAction() {
      return this.isConfigLoaded && this.config.actions.includes('duplicate')
    },

    hasRight() {
      switch (this.useCase) {
        case 'segments':
        case 'campaigns':
        case 'emails':
        case 'messages':
        case 'refund_offers':
        case 'bonus_offers':
        case 'grouped_offers':
          return this.permittedActions.includes('animation_write')
        default:
          return false
      }
    },

    scopedSlotsForChild() {
      return fromPairs(
        filter(Object.entries(this.$scopedSlots), ([key, _]) =>
          key.startsWith('item.action')
        )
      )
    }
  },
  mounted() {
    this.fetchConfig()
  },
  methods: {
    retry() {
      return this.fetchConfig()
    },

    openShowModal(record) {
      this.selectedRecord = record
      this.isModalShowOpen = true
    },

    openEditModal(record) {
      this.selectedRecord = record
      this.isModalEditOpen = true
    },

    openDuplicateModal(record) {
      this.selectedRecord = record
      this.isModalDuplicateOpen = true
    },

    fetchConfig() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadedKey: 'isConfigLoaded',
        isLoadingKey: 'isConfigLoading',
        errorKey: 'errorOnFetchConfig',

        axios: {
          url: `/crud/${this.useCase}/config`,
          params: {
            program_identifier: this.selectedProgram.identifier
          }
        },

        onSuccess: (result) => {
          this.config = result
        }
      })
    }
  }
}
</script>
