import { CancelToken } from 'axios'
import CANCEL_TOKEN_ERROR_ID from '~/utils/constants/cancel-token-error-id'
export default function cancelPreviousRequestAndGetNewCancelToken({ $vm }) {
  if ($vm.cancelToken) {
    $vm.cancelToken.cancel(CANCEL_TOKEN_ERROR_ID)
    $vm.cancelToken = null
  }
  const source = CancelToken.source()
  $vm.cancelToken = source
  return source.token
}
