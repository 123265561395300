import * as tabsAnimationMessages from '~/components/layouts/animation/messages/Bar.vue'
import * as tabsAnimationOffers from '~/components/layouts/animation/offers/Bar.vue'
import * as tabsMetricsCanals from '~/components/layouts/metrics/canals/Bar.vue'
import * as tabsMetricsProgram from '~/components/layouts/metrics/program/Bar.vue'
import * as tabsProgram from '~/components/layouts/program/Bar.vue'
import * as tabsSourcesProducts from '~/components/layouts/sources/products/Bar.vue'

export default {
  title: 'Dashboard',

  settings: {
    changeProgram: 'changer de programme',
    createProgram: 'créer un programme',
    manageAdmins: 'gérer les administrateurs',
    analyticsJobs: 'extractions de données'
  },

  backoffice: 'Backoffice',

  programHasNoCurrentPeriod: "ce programme n'a pas de période en cours",

  user: {
    header: {
      bannedAt: 'Banni le {date}',
      banEndedAt: 'Fin',
      oldBans: 'aucun ancien ban | 1 ancien ban | {count} anciens bans'
    },
    tabs: {
      timeline: 'User Logbook',
      declarations: 'Déclarations',
      profile: 'Profil',
      app: 'App'
    },

    app: {
      tabs: {
        misc: 'misc',
        coupons: 'coupons',
        timeline: 'timeline',
        cards: 'cards',
        pot: 'pot'
      }
    }
  },
  metrics: {
    canals: {
      title: 'Canaux',
      tabs: {
        [tabsMetricsCanals.TAB_GENERAL]: 'Général',
        [tabsMetricsCanals.TAB_ONBOARDING_FUNNEL]: 'Onboarding',
        [tabsMetricsCanals.TAB_POINTS_OF_SALE]: 'Points de ventes'
      }
    },
    program: {
      title: 'Métriques',
      tabs: {
        [tabsMetricsProgram.TAB_GENERAL]: 'Général',
        [tabsMetricsProgram.TAB_GENEROSITY]: 'Générosité',
        [tabsMetricsProgram.TAB_SALES]: 'Ventes',
        [tabsMetricsProgram.TAB_USAGE]: 'Usage',
        [tabsMetricsProgram.TAB_INDIVIDUAL_BEHAVIOUR]: 'Comportement individuel'
      }
    },
    periods: {
      current: 'Période actuelle',
      previous:
        'Période précédente | Période précédente | Périodes précédentes',
      custom: 'Personnalisé',
      currentMonthIsStartMonthOfPeriod:
        'ce mois est le premier de la période (aucun mois échus encore)',
      expiredMonths: 'Mois échus (de la période)',
      previousMonth: 'Mois précédent',
      currentMonth: 'Mois en cours',
      expiredTwelveMonths: '12 mois échus'
    }
  },
  animation: {
    offers: {
      title: 'Offres',
      tabs: {
        [tabsAnimationOffers.TAB_REFUND]: 'Remboursement',
        [tabsAnimationOffers.TAB_BONUS]: 'Bonus',
        [tabsAnimationOffers.TAB_GROUPED]: 'Groupées'
      }
    },
    messages: {
      title: 'Messages',
      tabs: {
        [tabsAnimationMessages.TAB_EMAILS]: 'Emails',
        [tabsAnimationMessages.TAB_MESSAGES]: 'Messages'
      }
    }
  },
  program: {
    tabs: {
      [tabsProgram.TAB_PERIODS]: 'Périodes',
      [tabsProgram.TAB_PERIODS_NEW]: 'Périodes',
      [tabsProgram.TAB_CATALOG]: 'Catalogue',
      [tabsProgram.TAB_DESCRIPTIONS]: 'Descriptions',
      [tabsProgram.TAB_ASSETS]: 'Images',
      [tabsProgram.TAB_COLOR]: 'Couleur',
      [tabsProgram.TAB_PRECISION]: 'Précisions',
      [tabsProgram.TAB_BRANDS]: 'Marques',
      [tabsProgram.TAB_VISIBILITY]: 'Visibilité',

      [tabsProgram.TAB_POINTS]: 'Points',
      [tabsProgram.TAB_STAMPS]: 'Tampons',
      [tabsProgram.TAB_PUZZLE]: 'Puzzle',
      [tabsProgram.TAB_APPLET]: 'Applet'
    },

    importProducts: {
      title: 'Importer les produits',
      wizard: {
        steps: {
          1: 'Fichier à importer',
          2: 'Aperçu'
        }
      },
      instructions: {
        useThisFileAsReference: 'Utilisez ce fichier comme référence',
        eansList: 'liste des EANs',

        steps: {
          title: 'Étapes',
          1: 'Créer un onglet avec la liste des produits à importer',
          2: "Télécharger l'onglet comme un fichier CSV",
          3: 'Envoyer le fichier avec le formulaire ci dessous'
        }
      },
      preview: {
        title: 'aperçu',
        actionsTahtWillBeTaken: 'Actions qui vont être effectuées',
        productGroupsTreeView: 'Arborescence des groupes de produits',
        productExists: 'Le produit existe déjà',
        productWillBeCreated: 'Le produit va être créé',
        eanOnAPreviousLine: 'EAN présent sur une ligne précédente',
        brandExists: 'La marque existe déjà',
        brandMustBeCreatedBefore: 'La marque doit être créée avant',
        fileHasSomeErrors: 'Le fichier contient des erreurs.',
        youNeedToCorrectThemToImport:
          'Vous devez les corriger avant de pouvoir importer.'
      },
      import: 'importer',
      importSuccessful: 'import fait avec succès'
    }
  },
  sources: {
    products: {
      title: 'Produits',
      tabs: {
        [tabsSourcesProducts.TAB_TREE]: 'Groupes de produits',
        [tabsSourcesProducts.TAB_DENOMS]: 'Classification des libellés',
        [tabsSourcesProducts.TAB_DENOMS_V2]: 'Classification des libellés v2'
      }
    }
  }
}
