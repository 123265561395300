// import { orderBy } from 'lodash-es'

import CANCEL_TOKEN_ERROR_ID from '@/utils/constants/cancel-token-error-id'
import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

function setState(commit, result) {
  commit('pushDeclarations', result.declarations)
  commit('setHasMore', result.has_more)
  commit('setNextPaginationKey', result.next_pagination_key)
}

export const fetchAll = async function({ state, commit }, { userId }) {
  commit('setLoadingState', true)
  commit('setDeclarations', [])
  commit('resetError')

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/declarations`, {
      cancelToken,
      params: {
        filters: {
          editor_ids: state.selectedAppIds
        }
      }
    })
  } catch (error) {
    if (error.message === CANCEL_TOKEN_ERROR_ID) return
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, response.data.result)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const fetchMore = async function({ state, commit }, { userId }) {
  if (state.isfetchingMore) return

  commit('setFetchingMoreState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/declarations`, {
      params: {
        pagination_key: state.nextPaginationKey,
        filters: {
          editor_ids: state.selectedAppIds
        }
      }
    })
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, response.data.result)

  commit('setFetchingMoreState', false)
}
