import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"benefits"},[_c(VDataTable,{staticClass:"benefits__table",attrs:{"headers":_vm.BENEFITS_HEADERS,"items":_vm.items,"item-class":"rowClass","disable-pagination":"","hide-default-footer":"","locale":_vm.$i18n.locale},on:{"click:row":_vm.openEarnsBurnsDetails},scopedSlots:_vm._u([{key:"item.program_name",fn:function(ref){
var item = ref.item;
return [(item.program_roundable_image_url)?_c(VAvatar,{attrs:{"tile":""}},[_c(VImg,{attrs:{"src":item.program_roundable_image_url,"alt":item.program_name}})],1):_c('span',[_vm._v("\n        "+_vm._s(item.program_name)+"\n      ")])]}},{key:"item.total_price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm._f("money")(item.total_refunded_amount,{ suffix: ' €' }))+"\n    ")]}},{key:"item.total_refunded_amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm._f("money")(item.total_refunded_amount,{ suffix: ' €' }))+"\n    ")]}}])}),_vm._v(" "),(_vm.selectedItem)?_c('BenefitsModal',{attrs:{"program-name":_vm.selectedItem.program_name,"program-identifier":_vm.selectedItem.identifier},on:{"close":function($event){_vm.selectedItem = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }