import Vue from 'vue'
import { cloneDeep, uniq } from 'lodash-es'

// import { uniq } from 'lodash-es'
// import createOffer from '~/utils/mocked-data/groupedOffer.js'

import createDefaultState from './state'

import eansTexteareaToArray from '~/utils/functions/forms/eansTexteareaToArray'

import { KIND_SPECIAL_OFFER } from '~/i18n/fr/pages/user/declaration.js'

import { BONUS_TYPE_FACTOR } from '~/components/features/crud/offers/steps/step2components/Bonus.vue'
import { REFUND_TYPE_ABSOLUTE } from '~/components/features/crud/offers/steps/step2components/Refund.vue'
import { GROUPED_OFFER_TYPE_PERCENT } from '~/components/features/crud/offers/steps/step2components/Grouped.vue'

const createEmptyVariant = () => ({
  refundValue: null,
  minItems: '1'
})

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

const createDefaultSpecialOffer = () => ({
  // should not be mutated

  programIdentifier: null,
  visibility: KIND_SPECIAL_OFFER,

  // can be mutated

  /// tab 1

  name: null,
  type: null,

  /// tab 2

  refundType: REFUND_TYPE_ABSOLUTE,
  refundValue: null,
  refundBonusPoints: null,
  refundRecommendedPrice: null,
  refundProductsEans: [],

  bonusValue: null,
  bonusType: BONUS_TYPE_FACTOR,
  bonusMaxQuantity: null,
  bonusMinItems: 1,
  bonusMatchingRegexp: null,
  bonusExceptionRegexp: null,
  // bonusMessageIdentifier: null,
  bonusDescriptionForUserReview: null,
  bonusTitleForWhatNow: null,
  bonusDescriptionForWhatNow: null,
  bonusProductsEans: [],

  groupedRefundQuota: '1',
  groupedRefundType: GROUPED_OFFER_TYPE_PERCENT,
  groupedRefundValue: null,
  groupedRefundMinItems: '1',
  groupedRefundVariants: [],
  groupedRefundProductsRanges: [[]],

  /// tab 3

  formattedDescription: null,
  internalDescription: null,
  illustrationFile: null,
  illustrationUrl: null
})

export function initWithNewSpecialOffer(state, record) {
  state.original = createDefaultSpecialOffer()

  state.original.programIdentifier = record.program_identifier
  state.original.type = record.type

  // state.modified = createOffer()
  state.modified = cloneDeep(state.original)

  state.isLoaded = true
}

const useRecord = (state, record) => {
  state.original = createDefaultSpecialOffer()
  /// tab 1
  state.original.programIdentifier = record.program_identifier
  state.original.name = record.name
  state.original.type = record.type
  /// tab 2
  if (record.refund) {
    state.original.refundType = record.refund.type
    state.original.refundValue = record.refund.value
    state.original.refundBonusPoints = record.refund.bonus_points
    state.original.refundRecommendedPrice = record.refund.recommended_price
    state.original.refundProductsEans = record.refund.products_eans
  }
  if (record.bonus) {
    state.original.bonusValue = record.bonus.value
    state.original.bonusType = record.bonus.type
    state.original.bonusMaxQuantity = record.bonus.max_quantity
    state.original.bonusMinItems = record.bonus.min_items
    state.original.bonusMatchingRegexp = record.bonus.matching_regexp
    state.original.bonusExceptionRegexp = record.bonus.exception_regexp
    state.original.bonusTitleForWhatNow = record.bonus.title_for_what_now
    state.original.bonusDescriptionForWhatNow =
      record.bonus.description_for_what_now
    state.original.bonusDescriptionForUserReview =
      record.bonus.description_for_user_review
    state.original.bonusMessageIdentifier = record.bonus.message_identifier
    state.original.bonusProductsEans = record.bonus.products_eans
  }
  if (record.grouped_refund) {
    state.original.groupedRefundQuota = record.grouped_refund.quota
    state.original.groupedRefundType = record.grouped_refund.type
    state.original.groupedRefundValue = record.grouped_refund.value
    state.original.groupedRefundMinItems = String(
      record.grouped_refund.min_items
    )
    state.original.groupedRefundVariants = record.grouped_refund.variants.map(
      (v) => ({
        refundValue: v.refund_value,
        minItems: String(v.min_items)
      })
    )
    state.original.groupedRefundProductsRanges =
      record.grouped_refund.products_ranges
  }
  /// tab 3
  state.original.description = record.description
  state.original.formattedDescription =
    record.formatted_description || record.description
  state.original.internalDescription = record.internal_description
  state.original.illustrationFile = null
  state.original.illustrationUrl = record.illustration_url

  // state.modified = createOffer()
  state.modified = cloneDeep(state.original)
  state.modified.illustrationUrl = null
}

export function initWithRecord(state, record) {
  useRecord(state, record)
  state.modified.id = record.id
  state.isLoaded = true
}

export function duplicateRecord(state, record) {
  useRecord(state, record)
  state.modified.id = null
  state.modified.illustrationUrl = record.illustration_url
  state.isLoaded = true
}

export function updateAttribute(state, { key, value }) {
  Vue.set(state.modified, key, value)
}

export const updateRefundProductsEans = (state, value) => {
  state.modified.refundProductsEans = uniq(eansTexteareaToArray(value))
}

export const updateBonusProductsEans = (state, value) => {
  state.modified.bonusProductsEans = uniq(eansTexteareaToArray(value))
}

export function updateVariantAttribute(state, { index, key, value }) {
  Vue.set(state.modified.groupedRefundVariants[index], key, value)
}

export function addVariantToGroupedRefund(state) {
  state.modified.groupedRefundVariants.push(createEmptyVariant())
}

export function removeLastVariantFromGroupedRefund(state) {
  state.modified.groupedRefundVariants.splice(
    state.modified.groupedRefundVariants.length - 1,
    1
  )
}

export function changeEansOnGroupedRefunds(state, { index, value }) {
  Vue.set(
    state.modified.groupedRefundProductsRanges,
    index,
    uniq(eansTexteareaToArray(value))
  )
}

export function addProductToGroupedRefund(state) {
  state.modified.groupedRefundProductsRanges.push([])
}

export function removeLastProductFromGroupedRefund(state) {
  state.modified.groupedRefundProductsRanges.splice(
    state.modified.groupedRefundProductsRanges.length - 1,
    1
  )
}
