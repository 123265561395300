export default {
  retailer: 'enseigne',
  notIdentifiedYet: 'pas encore identifiée',
  dateOfPurchase: "Date d'achat",

  purchase: {
    newPurchaseBefore: 'Nouvel achat avant {text}',
    newPurchaseAfter: 'Nouvel achat après {text}',
    newPurchase: 'Nouvel achat'
  }
}
