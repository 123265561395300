<template>
  <v-dialog v-model="isOpen" scrollable @click:outside="isOpen = false">
    <v-card v-if="isLoading" color="primary" dark>
      <v-card-text>
        {{ $t('global.loading') }}...
        <v-progress-linear indeterminate color="white" class="mb-0" />
      </v-card-text>
    </v-card>

    <v-card v-else-if="error" color="primary" dark>
      <div class="pa-3 d-flex align-center justify-center">
        <DisplayError
          :error="error"
          retriable
          @retry="fetch({ userId: $route.params.userId })"
        />
      </div>
    </v-card>

    <v-card v-else-if="!isLoaded" color="primary" dark />

    <v-card v-else>
      <v-card-title>
        {{ programName }}
        <v-spacer v-if="cashbackFrozenReady" />
        <v-switch
          v-if="cashbackFrozenReady"
          v-model="isCashbackFrozen"
          :disabled="isTogglingCashbackFrozen"
          :loading="isTogglingCashbackFrozen"
          :label="
            $t(
              `pages.user.profile.earnsAndBurns.isCashbackFrozen.${
                isCashbackFrozen ? 'on' : 'off'
              }`
            )
          "
          :error-messages="togglingErrorMessages"
          :success-messages="togglingSuccessMessages"
          class="v-input--reverse"
        />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="HEADERS"
          :items="items"
          disable-pagination
          hide-default-footer
          :locale="$i18n.locale"
        >
          <template #item.origin="{ item }">
            <BenefitModalItemOrigin :item="item" />
            <br v-if="item.origin_details && item.origin_details.length > 0" />
            <span v-if="item.origin_details && item.origin_details.length > 0">
              (
              <span v-for="(detail, index) in item.origin_details" :key="index">
                <BenefitModalItemDetail :detail="detail" />
                <span v-if="index !== item.origin_details.length - 1">,</span>
              </span>
              )
            </span>
          </template>
          <template #item.datetime="{ item }">
            <!-- TODO: update to vuetify 2.5 and remove this "span" for ".text-no-wrap" -->
            <span class="text-no-wrap">
              {{ item.datetime | momentFormat('human-long') }}
            </span>
          </template>
          <template #item.amountEarned="{ item }">
            {{ item.amountEarned }}
            <span v-if="item.amount_without_capping" class="error--text">
              ({{ item.amount_without_capping }})
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="isOpen = false">
          {{ $t('global.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import BenefitModalItemOrigin from './BenefitModalItemOrigin'
import BenefitModalItemDetail from './BenefitModalItemDetail'
import DisplayError from '~/components/layouts/DisplayError'
import { fetchCoreFromComponentAndHandleError } from '~/utils/functions/fetch-and-handle-errors'
const KEYS = ['origin', 'datetime', 'amountEarned', 'amountBurned', 'balance']
export default {
  components: {
    BenefitModalItemOrigin,
    BenefitModalItemDetail,
    DisplayError
  },
  props: {
    programName: {
      type: String,
      required: true
    },
    programIdentifier: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: true,
      HEADERS: KEYS.map((key) => ({
        value: key,
        text: this.$t(`pages.user.profile.earnsAndBurns.headers.${key}`),
        cellClass: key === 'datetime' ? 'text-no-wrap' : null
      })),

      isLoadingCashbackFrozen: false,
      isLoadedCashbackFrozen: false,

      isCashbackFrozen: null,

      isTogglingCashbackFrozen: false,
      togglingCashbackFrozenSuccess: false,
      togglingCashbackFrozenError: null
    }
  },
  computed: {
    ...mapState('user/profile/earnsAndBurns', [
      'isLoading',
      'isLoaded',
      'error',
      'earnsAndBurns'
    ]),
    ...mapGetters('user', ['routeBasePath']),

    showCashbackFrozen() {
      return this.programName === 'Scanobar'
    },
    cashbackFrozenReady() {
      return (
        this.showCashbackFrozen &&
        this.isLoadedCashbackFrozen &&
        this.isCashbackFrozen !== null
      )
    },
    togglingSuccessMessages() {
      return this.togglingCashbackFrozenSuccess
        ? [this.$t('pages.user.profile.earnsAndBurns.isCashbackFrozen.updated')]
        : []
    },
    togglingErrorMessages() {
      return this.togglingCashbackFrozenError
        ? [this.$t('pages.user.profile.earnsAndBurns.isCashbackFrozen.error')]
        : []
    },

    programEarnsAndBurns() {
      return this.earnsAndBurns && this.earnsAndBurns[this.programIdentifier]
    },
    items() {
      if (this.isLoading) return

      let balance = 0
      return this.programEarnsAndBurns.map((earnOrBurn) => {
        balance += earnOrBurn.amount

        let originLinkUrl
        let originLinkTo
        if (earnOrBurn.origin_record) {
          switch (earnOrBurn.origin_record.kind) {
            case 'declaration':
              originLinkTo = `${this.routeBasePath}/declarations/${earnOrBurn.origin_record.identifier}`
              break
          }
        }

        return {
          ...earnOrBurn,
          amountEarned: earnOrBurn.kind === 'earn' ? earnOrBurn.amount : null,
          amountBurned: earnOrBurn.kind === 'burn' ? earnOrBurn.amount : null,
          originLinkTo,
          originLinkUrl,
          balance
        }
      })
    }
  },
  watch: {
    isOpen(val) {
      if (!val) {
        this.$emit('close')
      }
    },
    isCashbackFrozen(_newValue, oldValue) {
      if (oldValue === null) return

      this.toggleCashbackFrozenStatus()
    }
  },
  mounted() {
    if (this.showCashbackFrozen) {
      this.fetchCashbackFrozenStatus()
    }
  },
  methods: {
    ...mapActions('user/profile/earnsAndBurns', ['fetch']),

    fetchCashbackFrozenStatus() {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadedKey: 'isLoadedCashbackFrozen',
        isLoadingKey: 'isLoadingCashbackFrozen',
        errorInToast: true,

        axios: {
          url: `/users/${this.$route.params.userId}/programs/${this.programIdentifier}/get_cashback_frozen_status`
        },

        onSuccess: (result) => {
          this.isCashbackFrozen = result.is_cashback_frozen
        }
      })
    },

    toggleCashbackFrozenStatus() {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isTogglingCashbackFrozen',
        errorKey: 'togglingCashbackFrozenError',

        axios: {
          method: 'POST',
          url: `/users/${this.$route.params.userId}/programs/${this.programIdentifier}/toggle_cashback_frozen_status`
        },

        onSuccess: (result) => {
          this.isCashbackFrozen = result.is_cashback_frozen

          this.togglingCashbackFrozenSuccess = true
          setTimeout(() => {
            this.togglingCashbackFrozenSuccess = false
          }, 1000)
        },
        onError: () => {
          this.isCashbackFrozen = !this.isCashbackFrozen
        }
      })
    }
  }
}
</script>

<style lang="scss">
// https://github.com/vuetifyjs/vuetify/issues/7283

// Reversed input variant
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  // justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
</style>
