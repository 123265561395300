import CANCEL_TOKEN_ERROR_ID from '@/utils/constants/cancel-token-error-id'
import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

import { handleAxiosProxyAnalyticsErrorServerFromStore } from '@/utils/functions/handle-errors'

export default async ({ ctx, store, tab, proxy }) => {
  const { state, rootState, commit } = ctx

  // not sure if relevant
  // could be left out to be more general
  if (!rootState.metrics.program.selectedPeriod) return

  commit('reset')
  commit('setLoadingState', true)

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })

  let response
  try {
    response = await store.$axios.post('/api/proxy/analytics', proxy, {
      baseURL: '/',
      cancelToken
    })
  } catch (error) {
    if (error.message === CANCEL_TOKEN_ERROR_ID) return
    handleAxiosProxyAnalyticsErrorServerFromStore({
      error,
      commit,
      store
    })
    commit('setLoadingState', false)
    return
  }

  if (response.data.result.errors) {
    commit(
      'metrics/program/setTabErrors',
      { tab, errors: response.data.result.errors },
      { root: true }
    )
    commit('setLoadingState', false)
    return
  }

  commit('handleResult', response.data.result)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
