import qs from 'qs'
import moment from 'moment'

import { Context } from '@nuxt/types'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse } from 'axios'

import coreOrigin from '~/utils/constants/origin-core'
import setAxiosAdminHeaders, {
  updateAdminHeaders
} from '~/utils/functions/setAxiosAdminHeaders'
import setAxiosProgramHeaders, {
  updateProgramHeaders
} from '~/utils/functions/setAxiosProgramHeaders'

function updateLocale(locale: string, $axios: NuxtAxiosInstance) {
  $axios.defaults.headers.common['X-PurchEase-Locale'] = locale
  moment.locale(locale)
}

export default ({ app, $axios, store }: Context) => {
  $axios.defaults.baseURL = coreOrigin
  $axios.defaults.paramsSerializer = (params: any) =>
    qs.stringify(params, { arrayFormat: 'brackets' })

  // MONKEYPATCH :
  // setAdmin is called server side, it will not be called client side
  // => axios headers are not set client side
  if (store.getters['auth/isLogged']) {
    setAxiosAdminHeaders($axios, store.state.auth.admin)
  }

  // MONKEYPATCH :
  // setProgramInStores is called server side, it will not be called client side
  // => axios headers are not set client side
  if (store.getters['auth/isProgramSelected']) {
    setAxiosProgramHeaders($axios, store.state.auth.selectedProgram)
  }

  updateLocale(app.i18n.locale, $axios)
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    updateLocale(newLocale, $axios)
  }

  // refresh admin payload if requested by server (and resend the request)
  $axios.interceptors.response.use(async function(response: AxiosResponse) {
    if (response.headers['x-refresh-current-admin'] !== 't') return response

    const adminBefore = store.state.auth.admin

    await store.dispatch('auth/refreshAdmin')

    const adminAfter = store.state.auth.admin
    if (adminBefore === adminAfter) return response

    updateAdminHeaders(response.config.headers, store.state.auth.admin)

    // do not duplicate requests that might update DB
    if (response.config.method !== 'get') return response

    return $axios.request(response.config)
  })

  // refresh program payload if requested by server (and resend the request)
  $axios.interceptors.response.use(async function(response: AxiosResponse) {
    // let admin refresh first
    if (response.headers['x-refresh-current-admin'] === 't') return response

    if (response.headers['x-refresh-current-program'] !== 't') return response

    const programBefore = store.state.auth.selectedProgram

    await store.dispatch('auth/refreshProgram')

    const programAfter = store.state.auth.selectedProgram
    if (programBefore === programAfter) return response

    updateProgramHeaders(
      response.config.headers,
      store.state.auth.selectedProgram
    )

    // do not duplicate requests that might update DB
    if (response.config.method !== 'get') return response

    return $axios.request(response.config)
  })
}
