export default {
  'work-in-progress': 'En construction ...',

  labels: {
    id: 'ID',
    actions: 'Actions'
  },

  loading: 'chargement',
  loadingDotDotDot: 'Chargement...',

  example: 'exemple',
  preview: 'aperçu',

  retry: 'réessayer',
  copy: 'copier',
  close: 'fermer',
  add: 'ajouter',
  validate: 'valider',
  invalidate: 'invalider',
  save: 'enregistrer',
  cancel: 'annuler',
  create: 'créer',
  update: 'mettre à jour',
  send: 'envoyer',
  submit: 'soumettre',
  unlock: 'débloquer',
  lock: 'bloquer',

  mandatory: 'obligatoire',
  optional: 'optionel',

  mandatoryFields: 'champs obligatoires',

  details: 'détails',

  calendar: {
    full: {
      lessThanADay: "moins d'un jour",
      day: 'aucun jour | un jour | {count} jours',
      month: 'aucun mois | un mois | {count} mois',
      year: 'aucune année | une année | {count} années'
    }
  },

  reason: 'raison',
  origin: 'origine',

  previous: 'précédent',
  next: 'suivant',

  or: 'ou',
  and: 'et',

  destroy: 'détruire',
  confirmIrreversibleDestroy:
    'Êtes-vous sûr de vouloir supprimer {item} ? Cette action est irréversible.',

  durations: {
    seconds: '0 seconde | 1 seconde | {count} secondes',
    minutes: '0 minute | 1 minute | {count} minutes',
    hours: '0 heure | 1 heure | {count} heures',
    days: '0 jour | 1 jour | {count} jours'
  },

  mustConfirmChoice: 'je confirme mon choix',

  toSentence: {
    connector: ', ',
    lastWordConnector: ' et '
  },

  saveInProgress: 'enregistrement en cours',
  saved: 'enregistré',

  automaticallyCreated: 'automatiquement créé',

  image: {
    remove: "supprimer l'image",
    sendAFile: 'envoyer un fichier',
    useAnUrl: 'utiliser une URL',
    linkIsNotAnImage: 'ce lien ne pointe pas vers une image',
    sizes: 'dimensions'
  },

  brand: 'marque',
  brands: 'marques',

  template: 'template',

  doNotFilterBasedOnLoyaltyProgram:
    'Ne pas filtrer en fonction du programme sélectionné',
  notFilteredBasedOnLoyaltyProgram:
    'Ces éléments ne sont pas être filtrés en fonction du programme sélectionné'
}
