import moment from 'moment'
import { every } from 'lodash-es'

import { momentFormat } from '~/plugins/filters'

import { KIND_CATALOG } from '~/i18n/fr/pages/user/declaration.js'
import {
  OFFER_TYPE_REFUND,
  OFFER_TYPE_BONUS,
  OFFER_TYPE_GROUPED
} from '~/i18n/fr/crud.js'

import toFormData, {
  toFormDataWithArrayIndex
} from '~/utils/functions/toFormData.js'

export const selectedTypeTitleI18nKey = (state) =>
  state.modified && `crud.offers.types.${state.modified.type}`

export const isCatalog = (state) =>
  state.modified && state.modified.visibility === KIND_CATALOG

export const catalogAvailableDateForHuman = (state) =>
  state.modified &&
  state.modified.catalogAvailableDate &&
  momentFormat(state.modified.catalogAvailableDate, 'date')

export const catalogAvailableDateIso8601 = (state) =>
  state.modified &&
  state.modified.catalogAvailableDate &&
  moment(state.modified.catalogAvailableDate).format('YYYY-MM-DD')

export const catalogExpiredDateForHuman = (state) =>
  state.modified &&
  state.modified.catalogExpiredDate &&
  momentFormat(state.modified.catalogExpiredDate, 'date')

export const catalogExpiredAvailableDateIso8601 = (state) =>
  state.modified &&
  state.modified.catalogExpiredDate &&
  moment(state.modified.catalogExpiredDate).format('YYYY-MM-DD')

export const anyRefundProductsEans = (state) =>
  state.modified && state.modified.refundProductsEans.length > 0

export const refundProductsEansAreOnlyEans = (state) =>
  state.modified &&
  state.modified.refundProductsEans &&
  every(state.modified.refundProductsEans, (ean) => ean.match(/^\d+$/))

export const bonusProductsEansForTextarea = (state) =>
  state.modified && state.modified.bonusProductsEans
    ? state.modified.bonusProductsEans.join(', ')
    : ''

export const anyBonusProductsEans = (state) =>
  state.modified && state.modified.bonusProductsEans.length > 0

export const bonusProductsEansAreOnlyEans = (state) =>
  state.modified &&
  state.modified.bonusProductsEans &&
  every(state.modified.bonusProductsEans, (ean) => ean.match(/^\d+$/))

function createPreviewer(keyForFile, keyForUrl) {
  return (state) => {
    if (state.modified[keyForFile]) {
      return URL.createObjectURL(state.modified[keyForFile])
    }
    if (state.modified[keyForUrl]) {
      return state.modified[keyForUrl]
    }

    return state.original && state.original[keyForUrl]
  }
}

export const illustrationPreview = createPreviewer(
  'illustrationFile',
  'illustrationUrl'
)

export const backgroundImagePreview = createPreviewer(
  'backgroundImageFile',
  'backgroundImageUrl'
)

export const backendUrl = (state) => {
  switch (state.modified.type) {
    case OFFER_TYPE_REFUND:
    case OFFER_TYPE_GROUPED:
      return '/offers/create'
    case OFFER_TYPE_BONUS:
      return `/programs/${state.modified.programIdentifier}/create_bonus`
  }
}

export const payloadForBackend = (state) => {
  if (state.modified.type === OFFER_TYPE_BONUS) {
    return toFormData({
      id: state.modified.id,

      name: state.modified.name,
      program_identifier: state.modified.programIdentifier,

      value: state.modified.bonusValue,
      bonus_type: state.modified.bonusType,
      max_quantity: state.modified.bonusMaxQuantity,
      min_items: state.modified.bonusMinItems,
      matching_regexp: state.modified.bonusMatchingRegexp,
      exception_regexp: state.modified.bonusExceptionRegexp,
      title_for_what_now: state.modified.bonusTitleForWhatNow,
      description_for_what_now: state.modified.bonusDescriptionForWhatNow,
      description_for_user_review: state.modified.bonusDescriptionForUserReview,
      // message_identifier: state.modified.bonusMessageIdentifier,
      products_eans: state.modified.bonusProductsEans,

      illustration_url: state.modified.illustrationUrl,
      illustration_file: state.modified.illustrationFile,
      background_image_file: state.modified.backgroundImageFile,
      background_image_url: state.modified.backgroundImageUrl
    })
  }

  const res = {
    id: state.modified.id,

    program_identifier: state.modified.programIdentifier,
    visibility: state.modified.visibility,

    name: state.modified.name,
    type: state.modified.type,
    formatted_description: state.modified.formattedDescription,
    internal_description: state.modified.internalDescription,

    illustration_url: state.modified.illustrationUrl,
    illustration_file: state.modified.illustrationFile,
    background_image_file: state.modified.backgroundImageFile,
    background_image_url: state.modified.backgroundImageUrl
  }
  switch (state.modified.type) {
    case OFFER_TYPE_REFUND:
      res.refund = {
        value: state.modified.refundValue,
        bonus_points: state.modified.refundBonusPoints,
        type: state.modified.refundType,
        recommended_price: state.modified.refundRecommendedPrice,
        products_eans: state.modified.refundProductsEans
      }
      return toFormData(res)

    case OFFER_TYPE_GROUPED:
      res.grouped_refund = {
        quota: state.modified.groupedRefundQuota,
        type: state.modified.groupedRefundType,
        value: state.modified.groupedRefundValue,
        min_items: state.modified.groupedRefundMinItems,
        variants: state.modified.groupedRefundVariants.map((variant) => ({
          refund_value: variant.refundValue,
          min_items: variant.minItems
        })),
        products_ranges: state.modified.groupedRefundProductsRanges
      }
      return toFormDataWithArrayIndex(res)
  }
}
