import { find } from 'lodash-es'

import {
  STATUS_ERROR_ANALYTICS,
  SERVER_NOT_REACHABLE,
  SERVER_ERROR,
  UNKNOWN_ERROR_SERVER,
  UNAUTHORIZED,
  UNKNOWN_ERROR_USER
} from '@/utils/constants/errors'

const setOrDisplayError = ({ $vm, ...options }) => {
  if (options.errorKey) {
    $vm.$set($vm, options.errorKey, options.errorMessageI18n)
  }
  if (options.errorInToast) {
    $vm.addToast({
      text: options.errorMessageI18n,
      ...options.toastOptions
    })
  }
}

// use in components on call to core API
export const handleAxiosErrorServerCoreFromComponent = async ({
  $vm,
  error,
  errorKey,
  errorInToast,
  toastOptions
}) => {
  if (!error.response) {
    $vm.$airbrakeNotify({ error })
    setOrDisplayError({
      $vm,
      errorMessageI18n: $vm.$t(`errors.server.${SERVER_NOT_REACHABLE}`),
      errorKey,
      errorInToast,
      toastOptions
    })
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === 401) {
    if ($vm.$store.getters['auth/isLogged']) {
      await $vm.$store.dispatch('auth/logout', {
        redirect: $vm.$route.fullPath
      })
    }
    // display error message on login page
    $vm.$store.commit('auth/setIsUserInputError', true)
    $vm.$store.commit('auth/setError', UNAUTHORIZED)
    return
  } else if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    // => no need to notify airbrake again here
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    $vm.$airbrakeNotify({ error })
  }

  setOrDisplayError({
    $vm,
    errorMessageI18n: $vm.$t(`errors.server.${errorIdentifier}`),
    errorKey,
    errorInToast,
    toastOptions
  })
}

// use in components on call to analytics API
export const handleAxiosErrorServerAnalyticsFromComponent = async ({
  $vm,
  error,
  errorKey,
  errorInToast,
  toastOptions
}) => {
  if (!error.response) {
    $vm.$airbrakeNotify({ error })
    setOrDisplayError({
      $vm,
      errorMessageI18n: $vm.$t(`errors.server.${SERVER_NOT_REACHABLE}`),
      errorKey,
      errorInToast,
      toastOptions
    })
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === STATUS_ERROR_ANALYTICS && error.response.data.status === 401) {
    if ($vm.$store.getters['auth/isLogged']) {
      await $vm.$store.dispatch('auth/logout', {
        redirect: $vm.$route.fullPath
      })
    }
    // display error message on login page
    $vm.$store.commit('auth/setIsUserInputError', true)
    $vm.$store.commit('auth/setError', UNAUTHORIZED)
    return
  }

  if (status >= 500 && status < 600) {
    // error already notified on airbrake analytics
    // => no need to notify airbrake again here
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    $vm.$airbrakeNotify({ error })
  }

  setOrDisplayError({
    $vm,
    errorMessageI18n: $vm.$t(`errors.server.${errorIdentifier}`),
    errorKey,
    errorInToast,
    toastOptions
  })
}

// use in components on call to core API
export const handleAxiosErrorFrontendFromComponent = ({
  $vm,
  response,
  knownPublicErrorCodes,
  errorKey,
  errorInToast,
  toastOptions
}) => {
  const knowns = []
  const unknowns = []
  response.data.errors.forEach((error) => {
    const match = find(
      knownPublicErrorCodes,
      (code) => code === error.error_code
    )
    if (match) {
      knowns.push(error.error_message_i18n || error.error_message)
      return
    }

    if (error.error_message_i18n) {
      knowns.push(error.error_message_i18n)
      return
    }

    unknowns.push(error)
  })

  if (unknowns.length > 0) {
    $vm.$airbrakeNotifyUnhandledErrorCodes({
      errorCodes: unknowns.map((e) => e.error_code),
      response
    })
  }

  if (knowns.length > 0) {
    // TODO: handle multiples
    setOrDisplayError({
      $vm,
      errorMessageI18n: knowns[0],
      errorKey,
      errorInToast,
      toastOptions
    })
    return
  }

  setOrDisplayError({
    $vm,
    errorMessageI18n: $vm.$t(`errors.user.${UNKNOWN_ERROR_USER}`),
    errorKey,
    errorInToast,
    toastOptions
  })
}

// use in components on call to core API
// DEPRECATED
// in favor of handleAxiosErrorServerCoreFromComponent
export const handleErrorAxiosCoreComponent = async ({
  $vm,
  error,
  errorKey
}) => {
  if (!error.response) {
    $vm.$airbrakeNotify({ error })
    $vm.$set($vm, errorKey, SERVER_NOT_REACHABLE)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === 401) {
    if ($vm.$store.getters['auth/isLogged']) {
      await $vm.$store.dispatch('auth/logout', {
        redirect: $vm.$route.fullPath
      })
    }
    // display error message on login page
    $vm.$store.commit('auth/setIsUserInputError', true)
    $vm.$store.commit('auth/setError', UNAUTHORIZED)
    return
  } else if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    $vm.$airbrakeNotify({ error })
  }

  $vm.$set($vm, errorKey, errorIdentifier)
}

// use in store on call to core API
// DEPRECATED
// in favor of handleAxiosErrorServerFromStoreV3
export const handleErrorAxiosCoreStore = async ({
  commit,
  error,
  store,
  loadingMutationName = 'setLoadingState',
  settingErrorMutationName = 'setError'
}) => {
  if (!error.response) {
    store.$airbrakeNotify({ error })
    commit(settingErrorMutationName, SERVER_NOT_REACHABLE)
    commit(loadingMutationName, false)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === 401) {
    if (store.getters['auth/isLogged']) {
      await store.dispatch('auth/logout', {
        redirect: store.state.route.fullPath
      })
    }
    commit(loadingMutationName, false)
    // display error message on login page
    store.commit('auth/setIsUserInputError', true)
    store.commit('auth/setError', UNAUTHORIZED)
    return
  } else if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    store.$airbrakeNotify({ error })
  }

  commit(settingErrorMutationName, errorIdentifier)
  commit(loadingMutationName, false)
}

// use in store on call to core API
// DEPRECATED
// in favor of handleAxiosErrorServerFromStoreV3
export const handleAxiosErrorServerFromStore = async ({
  error,
  errorInToast,
  store,
  commit,
  loadingMutationName = 'setLoadingState'
}) => {
  if (!error.response) {
    store.$airbrakeNotify({ error })
    commit('setError', {
      isServer: true,
      code: SERVER_NOT_REACHABLE
    })
    commit(loadingMutationName, false)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === 401) {
    if (store.getters['auth/isLogged']) {
      await store.dispatch('auth/logout', {
        redirect: store.state.route.fullPath
      })
    }
    commit(loadingMutationName, false)
    // display error message on login page
    store.commit('auth/setIsUserInputError', true)
    store.commit('auth/setError', UNAUTHORIZED)
    return
  } else if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    store.$airbrakeNotify({ error })
  }

  if (errorInToast) {
    commit(
      'snackbar/addToast',
      {
        text: store.$i18n.t(`errors.server.${errorIdentifier}`)
      },
      { root: true }
    )
  } else {
    commit('setError', {
      isServer: true,
      code: errorIdentifier
    })
  }
  commit(loadingMutationName, false)
}

// use in store on call to core API
// DEPRECATED
// in favor of handleAxiosErrorFrontendFromStoreV4
export const handleAxiosErrorFrontendFromStore = ({
  response,
  store,
  commit
}) => {
  store.$airbrakeNotifyUnhandledBadRequest({ response })
  commit('setError', {
    isServer: false,
    code: UNKNOWN_ERROR_USER
  })
  commit('setLoadingState', false)
}

// use in store on call to analytics API
// DEPRECATED
// in favor of handleAxiosProxyAnalyticsErrorServerFromStoreV3
export const handleAxiosProxyAnalyticsErrorServerFromStore = ({
  commit,
  error,
  store,
  loadingMutationName = 'setLoadingState',
  errorMutationName = 'setError'
}) => {
  if (!error.response) {
    store.$airbrakeNotify({ error })
    commit(errorMutationName, SERVER_NOT_REACHABLE)
    commit(loadingMutationName, false)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  } else if (status >= 400 && status < 500) {
    // error.response.data.errors
    store.$airbrakeNotify({ error })
    errorIdentifier = UNKNOWN_ERROR_USER
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    store.$airbrakeNotify({ error })
  }

  commit(errorMutationName, errorIdentifier)
  commit(loadingMutationName, false)
}

// use in components on call to analytics API
export const handleAxiosProxyAnalyticsErrorServerFromComponent = ({
  $vm,
  error,
  errorKey,
  errorInToast,
  toastOptions
}) => {
  if (!error.response) {
    $vm.$airbrakeNotify({ error })
    setOrDisplayError({
      $vm,
      errorMessageI18n: $vm.$t(`errors.server.${SERVER_NOT_REACHABLE}`),
      errorKey,
      errorInToast,
      toastOptions
    })
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    $vm.$airbrakeNotify({ error })
  }

  setOrDisplayError({
    $vm,
    errorMessageI18n: $vm.$t(`errors.server.${errorIdentifier}`),
    errorKey,
    errorInToast,
    toastOptions
  })
}

// use in store on call to core API
export const handleAxiosErrorServerFromStoreV3 = async ({
  error,
  store,
  commit,
  mutationNameForLoadingState = 'setLoadingState',
  mutationNameForSettingError = 'setError',
  errorInToast = false,
  toastOptions = {}
}) => {
  if (!error.response) {
    store.$airbrakeNotify({ error })
    setOrDisplayErrorFromStore({
      text: store.$i18n.t(`errors.server.${SERVER_NOT_REACHABLE}`),
      commit,
      errorInToast,
      toastOptions,
      mutationNameForSettingError
    })
    commit(mutationNameForLoadingState, false)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status === 401) {
    if (store.getters['auth/isLogged']) {
      await store.dispatch('auth/logout', {
        redirect: store.state.route.fullPath
      })
    }
    commit(mutationNameForLoadingState, false)
    // display error message on login page
    store.commit('auth/setIsUserInputError', true)
    store.commit('auth/setError', UNAUTHORIZED)
    return
  } else if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    store.$airbrakeNotify({ error })
  }

  const text = store.$i18n.t(`errors.server.${errorIdentifier}`)
  setOrDisplayErrorFromStore({
    text,
    commit,
    errorInToast,
    toastOptions,
    mutationNameForSettingError
  })
  commit(mutationNameForLoadingState, false)
}

// use in store on call to core API
// deprecated in favor of handleAxiosErrorFrontendFromStoreV4
export const handleAxiosErrorFrontendFromStoreV3 = ({
  store,
  response,
  commit,
  mutationNameForLoadingState = 'setLoadingState',
  mutationNameForSettingError = 'setError',
  errorInToast = false,
  toastOptions = {}
}) => {
  store.$airbrakeNotifyUnhandledBadRequest({ response })
  setOrDisplayErrorFromStore({
    text: store.$i18n.t(`errors.user.${UNKNOWN_ERROR_USER}`),
    commit,
    errorInToast,
    toastOptions,
    mutationNameForSettingError
  })
  commit(mutationNameForLoadingState, false)
}

// use in store on call to core API
export const handleAxiosErrorFrontendFromStoreV4 = ({
  store,
  response,
  commit,
  mutationNameForLoadingState = 'setLoadingState',
  mutationNameForSettingError = 'setError',
  errorInToast = false,
  toastOptions = {}
}) => {
  const knowns = []
  const unknowns = []
  response.data.errors.forEach((error) => {
    if (error.key === 'public' && error.error_message_i18n) {
      knowns.push(error.error_message_i18n)
      return
    }

    unknowns.push(error)
  })

  if (unknowns.length > 0) {
    store.$airbrakeNotifyUnhandledErrorCodes({
      errorCodes: unknowns.map((e) => e.error_code),
      response
    })
  }

  if (knowns.length > 0) {
    // TODO: handle multiples
    setOrDisplayErrorFromStore({
      text: knowns[0],
      commit,
      errorInToast,
      toastOptions,
      mutationNameForSettingError
    })
    commit(mutationNameForLoadingState, false)
    return
  }

  setOrDisplayErrorFromStore({
    text: store.$i18n.t(`errors.user.${UNKNOWN_ERROR_USER}`),
    commit,
    errorInToast,
    toastOptions,
    mutationNameForSettingError
  })
  commit(mutationNameForLoadingState, false)
}

// use in store on call to analytics API
export const handleAxiosProxyAnalyticsErrorServerFromStoreV3 = ({
  commit,
  error,
  store,
  mutationNameForLoadingState = 'setLoadingState',
  mutationNameForSettingError = 'setError',
  errorInToast = false,
  toastOptions = {}
}) => {
  if (!error.response) {
    store.$airbrakeNotify({ error })
    setOrDisplayErrorFromStore({
      text: store.$i18n.t(`errors.server.${SERVER_NOT_REACHABLE}`),
      commit,
      errorInToast,
      toastOptions,
      mutationNameForSettingError
    })
    commit(mutationNameForLoadingState, false)
    return
  }

  const status = error.response.status
  let errorIdentifier

  if (status >= 500 && status < 600) {
    // error already notified on airbrake core
    errorIdentifier = SERVER_ERROR
  } else if (status >= 400 && status < 500) {
    // error.response.data.errors
    store.$airbrakeNotify({ error })
    errorIdentifier = UNKNOWN_ERROR_USER
  }

  if (!errorIdentifier) {
    errorIdentifier = UNKNOWN_ERROR_SERVER
    store.$airbrakeNotify({ error })
  }

  setOrDisplayErrorFromStore({
    text: store.$i18n.t(`errors.server.${errorIdentifier}`),
    commit,
    errorInToast,
    toastOptions,
    mutationNameForSettingError
  })
  commit(mutationNameForLoadingState, false)
}

const setOrDisplayErrorFromStore = ({
  text,
  commit,
  errorInToast,
  toastOptions,
  mutationNameForSettingError
}) => {
  if (errorInToast) {
    commit('snackbar/addToast', { ...toastOptions, text }, { root: true })
  } else {
    commit(mutationNameForSettingError, text)
  }
}
