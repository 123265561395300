import CANCEL_TOKEN_ERROR_ID from '~/utils/constants/cancel-token-error-id'
import cancelPreviousRequestAndGetNewCancelToken from '~/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forComponent'
import {
  handleAxiosErrorServerCoreFromComponent,
  handleAxiosErrorFrontendFromComponent
} from '~/utils/functions/handle-errors'

// component need to have "reset" and "handleResult" methods
export default async ({
  $vm,
  axios,
  isLoadedKey,
  isLoadingKey = 'isLoading',
  errorKey,
  errorInToast,
  toastOptions,
  reset,
  handleResult
}) => {
  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({ $vm })

  reset()
  $vm.$set($vm, isLoadingKey, true)

  let response
  try {
    response = await $vm.$axios.request({ ...axios, cancelToken })
  } catch (error) {
    if (error.message === CANCEL_TOKEN_ERROR_ID) return
    handleAxiosErrorServerCoreFromComponent({
      $vm,
      error,
      errorKey,
      errorInToast,
      toastOptions
    })
    $vm.$set($vm, isLoadingKey, false)
    return
  }

  if (response.data.errors) {
    handleAxiosErrorFrontendFromComponent({
      $vm,
      response,
      errorKey,
      errorInToast,
      toastOptions
    })
    $vm.$set($vm, isLoadingKey, false)
    return
  }

  handleResult(response.data.result)

  if (isLoadedKey) $vm.$set($vm, isLoadedKey, true)
  $vm.$set($vm, isLoadingKey, false)
}
